import React from 'react';
import styles from './TeamPage.module.css';
import { FaInstagram } from 'react-icons/fa'; // Import Instagram icon from react-icons library

const teamMembers = [
  {
    id: 1,
    name: 'Kieran',
    position: 'Bossman',
    instagram: 'kieranlusher', // Instagram username
    imageUrl: '/images/kl.jpeg',
  },
  {
    id: 2,
    name: 'Lewis',
    position: 'Barber',
    instagram: 'lewisjamesphelps', // Instagram username
    imageUrl: '/images/lewis.jpeg',
  },
  {
    id: 3,
    name: 'Luca',
    position: 'Barber',
    instagram: 'luca_.2', // Instagram username
    imageUrl: '/images/luca.jpeg',
  },
  {
    id: 4,
    name: 'Sonny',
    position: 'Barber',
    instagram: 'barber.sonnyb', // Instagram username
    imageUrl: '/images/sonny.jpeg',
  },
  // Add more team members as needed
];

const TeamPage = () => {
  return (
    <div className={styles.teamContainer}>
      <h2>Meet the Team</h2>
      <div className={styles.teamMembers}>
        {teamMembers.map(member => (
          <div key={member.id} className={styles.memberContainer}>
            <div className={styles.member}>
              <div className={styles.imageContainer}>
                <img src={member.imageUrl} alt={member.name} className={styles.image} />
              </div>
              <div className={styles.details}>
                <a
                  href={`https://www.instagram.com/${member.instagram}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.instagramLink}
                >
                  <h3>{member.name}</h3>
                  <div className={styles.username}>
                    <FaInstagram className={styles.instagramIcon} />
                    <h4>{member.instagram}</h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamPage;
