// Navbar.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.css';
import { FaBars } from 'react-icons/fa'; // Import hamburger icon from react-icons library

const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false); // State to toggle visibility of links

  const handleLinkClick = () => {
    setShowLinks(false); // Close the navbar when a link is clicked
  };

  return (
    <nav className={styles.navbar}>
      <Link to="/" className={styles.logo}>
        <img src='images/logo2.png' alt="Lush Logo" className={styles.logoImage} />
      </Link>
      <div className={styles.navMenu}>
        <div className={`${styles.links} ${showLinks ? styles.show : ''}`}>
          <Link to="/" className={styles.link} onClick={handleLinkClick}>
            Home
          </Link>
          <Link to="/about" className={styles.link} onClick={handleLinkClick}>
            About
          </Link>
          <Link to="/team" className={styles.link} onClick={handleLinkClick}>
            Team
          </Link>
          <Link to="/gallery" className={styles.link} onClick={handleLinkClick}>
            Gallery
          </Link>
          <Link to="/contact" className={styles.link} onClick={handleLinkClick}>
            Contact
          </Link>
        </div>
        <div className={styles.hamburger} onClick={() => setShowLinks(!showLinks)}>
          <FaBars />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
