import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Template from './components/template/Template';
import HomePage from './components/homepage/HomePage';
import AboutPage from './components/aboutpage/AboutPage';
import TeamPage from './components/teampage/TeamPage';
import GalleryPage from './components/gallerypage/GalleryPage';
import ContactPage from './components/contactpage/ContactPage';

const App = () => {
  return (
    <Router>
      <Template>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Template>
    </Router>
  );
};

export default App;
