import React, { useEffect, useRef } from 'react';
import 'ol/ol.css';
import Map from 'ol/Map';
import View from 'ol/View';
import { fromLonLat } from 'ol/proj';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer';
import { OSM, Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import styles from './OpenLayersMap.module.css';

const OpenLayersMap = () => {
  const mapContainer = useRef(null);

  useEffect(() => {
    if (mapContainer.current) {
      const lushBarbershopCoords = fromLonLat([-2.074445, 51.896020]);

      const marker = new Feature({
        geometry: new Point(lushBarbershopCoords),
      });

      const vectorLayer = new VectorLayer({
        source: new VectorSource({
          features: [marker],
        }),
        style: new Style({
          image: new Icon({
            src: 'images/logo2.png',
            alt: "Lush Logo",
            scale: 0.3,
          }),
        }),
      });

      const map = new Map({
        target: mapContainer.current,
        layers: [
          new TileLayer({
            source: new OSM(),
          }),
          vectorLayer,
        ],
        view: new View({
          center: lushBarbershopCoords,
          zoom: 19,
        }),
      });

      return () => {
        map.setTarget(null);
      };
    }
  }, []);

  return <div ref={mapContainer} className={styles.map} />;
};

export default OpenLayersMap;
