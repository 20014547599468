import React from 'react';
import styles from './GalleryPage.module.css';

import { useState } from 'react';

const images = [
  {
    id: 1,
    imageUrl: '/images/1.jpeg',
    enlargedUrl: '/images/1big.jpeg',
  },
  {
    id: 2,
    imageUrl: '/images/2.jpeg',
    enlargedUrl: '/images/2big.jpeg',
  },
  {
    id: 3,
    imageUrl: '/images/3.jpeg',
    enlargedUrl: '/images/3big.jpeg',
  },
  {
    id: 4,
    imageUrl: '/images/4.jpeg',
    enlargedUrl: '/images/4big.jpeg',
  },
  {
    id: 5,
    imageUrl: '/images/5.jpeg',
    enlargedUrl: '/images/5big.jpeg',
  },
  {
    id: 6,
    imageUrl: '/images/6.jpeg',
    enlargedUrl: '/images/6big.jpeg',
  },
  {
    id: 7,
    imageUrl: '/images/7.jpeg',
    enlargedUrl: '/images/7big.jpeg',
  },
  {
    id: 8,
    imageUrl: '/images/8.jpeg',
    enlargedUrl: '/images/8big.jpeg',
  },
  {
    id: 9,
    imageUrl: '/images/9.jpeg',
    enlargedUrl: '/images/9big.jpeg',
  },
  {
    id: 10,
    imageUrl: '/images/10.jpeg',
    enlargedUrl: '/images/10big.jpeg',
  },
  {
    id: 11,
    imageUrl: '/images/11.jpeg',
    enlargedUrl: '/images/11big.jpeg',
  },
  {
    id: 12,
    imageUrl: '/images/12.jpeg',
    enlargedUrl: '/images/12big.jpeg',
  },
  {
    id: 13,
    imageUrl: '/images/13.jpeg',
    enlargedUrl: '/images/13big.jpeg',
  },
  {
    id: 14,
    imageUrl: '/images/14.jpeg',
    enlargedUrl: '/images/14big.jpeg',
  },
  
  
];

const GalleryPage = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClosePopup = () => {
    setSelectedImage(null);
  };

  return (
    <div className={styles.galleryContainer}>
      <h2>Gallery</h2>
      <div className={styles.imageGrid}>
        {images.map((image) => (
          <div key={image.id} className={styles.imageContainer}>
            <img
              src={image.imageUrl}
              alt={`Image ${image.id}`}
              className={styles.image}
              onClick={() => handleImageClick(image)}
            />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className={styles.popup}>
          <div className={styles.popupInner}>
            <img src={selectedImage.enlargedUrl} alt={`Image ${selectedImage.id}`} />
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default GalleryPage;