import React, { useState, useEffect } from 'react';
import styles from './HomePage.module.css'; // Import CSS module for styling

const HomePage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [fade, setFade] = useState(false);
  const [nextImage, setNextImage] = useState(false); // New state to track image changes
  const [previousImage, setPreviousImage] = useState(false); // New state to track image changes
  const images = ['/images/shop1.jpeg', '/images/shop6.jpeg', '/images/shop2.jpeg', '/images/shop3.jpeg', '/images/shop4.jpeg', '/images/shop5.jpeg']; // Define your array of images

  useEffect(() => {
    // Set a timeout to simulate loading delay
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 1000); // Adjust the delay as needed

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (nextImage) {
      // Set fade to true to trigger fade out animation
      setFade(true);
      
      // Set a timeout to change the image after the fade out animation completes
      const timeout = setTimeout(() => {
        // Reset the fade state to trigger the fade in animation
        setFade(false);
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        setNextImage(false); // Reset imageChanged state
      }, 1000); // Wait for 500ms for the fade out animation to complete

      // Clear the timeout when the component unmounts or when imageChanged state changes
      return () => clearTimeout(timeout);
    }
    if (previousImage) {
        // Set fade to true to trigger fade out animation
        setFade(true);
        
        // Set a timeout to change the image after the fade out animation completes
        const timeout = setTimeout(() => {
          // Reset the fade state to trigger the fade in animation
          setFade(false);
          setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
          setPreviousImage(false); // Reset imageChanged state
        }, 1000); // Wait for 500ms for the fade out animation to complete
  
        // Clear the timeout when the component unmounts or when imageChanged state changes
        return () => clearTimeout(timeout);
      }
  }, [nextImage, previousImage]);

  const handleNextImage = () => {
    setNextImage(true); // Set imageChanged to true when changing the image
    
  };

  const handlePrevImage = () => {
    setPreviousImage(true); // Set imageChanged to true when changing the image
    
  };

  return (
    <div className={`${styles.homeContainer} ${isLoaded ? styles.fadeIn : ''}`}>
      <h1 className={styles.title}>
        LUSH BARBER SHOP
      </h1>
      <div className={styles.imageContainer}>
        <button className={styles.leftArrow} onClick={handlePrevImage}>
          &lt;
        </button>
        <img
          src={images[currentImageIndex]}
          alt="Barber Shop"
          className={`${styles.centralImage} ${fade ? styles.fadeOut : ''}`}
        />
        <button className={styles.rightArrow} onClick={handleNextImage}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default HomePage;
