import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar';
import styles from './Template.module.css'; // Import CSS module for styling

const Template = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // Set a timeout to simulate loading delay
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 0); // Adjust the delay as needed

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  // Function to handle button click
  const handleButtonClick = () => {
    // Redirect the user to the desired URL
    window.location.href = 'https://lushbarbershop.booksy.com'; // Replace 'https://example.com' with your desired URL
  };

  return (
    <div className={styles.container}>
      <Navbar />
      <div className={`${styles.backgroundImage} ${isLoaded ? styles.fadeIn : ''}`} />
      <div className={styles.content}>{children}</div>
      {/* Removed the footer */}
      <button className={styles.bookNowButton} onClick={handleButtonClick}>Book Now</button>
    </div>
  );
};

export default Template;
