import React from 'react';
import styles from './AboutPage.module.css'; // Import CSS module for styling
import OpenLayersMap from '../map/OpenLayersMap';

const AboutPage = () => {
  return (
    <div className={styles.aboutContainer}>
      <h2>About Us</h2>
      <p>Located at 72 Bath Rd, Cheltenham GL53 7JT. </p>
      <div className={styles.mapContainer}>
        <OpenLayersMap />
      </div>
      <div className={styles.imageContainer}>
          <img src='images/outside.jpeg' alt="Barber Shop" className={styles.image} />
      </div>
    </div>
  );
};

export default AboutPage;
