import React from 'react';
import styles from './ContactPage.module.css';
import { FaInstagram, FaGoogle, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactPage = () => {
  // Replace these placeholders with actual URLs
  const instagramUrl = 'https://www.instagram.com/lushbarbershop';
  const googleBusinessUrl = 'https://g.co/kgs/wi9YLgd';
  const businessEmail = 'Lushbarbershop@gmail.com';
  const businessAddress = '72 Bath Rd, Cheltenham GL53 7JT';

  return (
    <div className={styles.contactContainer}>
      <h2>Contact Us</h2>
      <div className={styles.contactDetails}>
        <div className={styles.contactItem}>
          <a href={instagramUrl} target="_blank" rel="noopener noreferrer"
          className={styles.link}>
            <FaInstagram className={styles.icon} />
            <span>Instagram</span>
          </a>
        </div>
        <div className={styles.contactItem}>
          <a href={googleBusinessUrl} target="_blank" rel="noopener noreferrer"
          className={styles.link}>
            <FaGoogle className={styles.icon} />
            <span>Google Business</span>
          </a>
        </div>
        <div className={styles.contactItem}>
          <a href={`mailto:${businessEmail}`}
          className={styles.link}>
            <FaEnvelope className={styles.icon} />
            <span>{businessEmail}</span>
          </a>
        </div>
        <div className={styles.contactItem}>
          <FaMapMarkerAlt className={styles.icon} />
          <span>{businessAddress}</span>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
